import { FC, useEffect, useState } from 'react';
import { useUsers } from '../../../hooks/useUsers';
import { store } from '../../../config/store';
import { AppSpinner } from '../../../components/Common/AppSpinner';
import { ContainerProps, Flex } from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';

interface Props extends ContainerProps {
    header?: string;
    isLoading?: boolean;
}

export const UnauthorizedLayout: FC<Props> = ({ children, isLoading }) => {
    const [userChanged, setUserChanged] = useState<boolean>(false);
    const user = store.getState().user.details;
    const { isAdmin } = useUsers();

    useEffect(() => {
        if(isAdmin()) setUserChanged(true);
    }, [user]);

    return (
        <Flex
            width='100vw'
            height='100vh'
            backgroundColor='backgroundApp'
            justifyContent='center'
            alignItems='center'
        >
            {isLoading
                ? <AppSpinner fullScreen />
                : userChanged ? <Navigate to='/panel' /> : children}
        </Flex>
    );
};