import { Block, BlockType, I18nEntry } from 'pol-met-types';
import { __ } from '../helpers/i18n';
import { I18nValues } from '../slices/configurator/visualisationSlice';


export const formatValue = (val: string | number | boolean) => {
  if (val === 'false' || val === false) return 'FALSE';
  if (val === 'true' || val === true) return 'TRUE';
  if (val === 'null' || val === null) return '0';
  if (typeof val === 'number') return val.toString();
  return `'${val}'`;
};

export const flattenBlock = (
  block: Block,
  formula = '',
  values: any,
  names: I18nValues,
  quotes = true,
  lang = '',
  insideIf = false
) => {
  if (block.type === BlockType.IF && block.blocks) {
    formula = `IF(${flattenBlock(
      block.blocks[0],
      '',
      values,
      names,
      true,
      '',
      true
    )}, ${flattenBlock(
      block.blocks[1],
      '',
      values,
      names,
      true,
      '',
      true
    )}, ${flattenBlock(block.blocks[2], '', values, names, true, '', true)})`;
  }
  if (block.type === BlockType.GROUP && block.blocks) {
    formula = `(${block.blocks
      .map((block) =>
        flattenBlock(block, '', values, names, true, '', insideIf)
      )
      .join(' ')})`;
  }
  if (block.type === BlockType.ROUND && block.blocks) {
    formula = `ROUND(${block.blocks
      .map((block) => flattenBlock(block, '', values, names))
      .join(' ')}, 0)`;


  }
  if (block.type === BlockType.POWER && block.blocks) {
    formula = `POWER(${block.blocks
      .map((block) => flattenBlock(block, '', values, names))
      .join(', ')})`;
  }
  if (block.type === BlockType.ROOT && block.blocks) {
    formula = `POWER(${flattenBlock(block.blocks[1], '', values, names)}, 1/${flattenBlock(block.blocks[1], '', values, names)})`;
  }
  if (block.type === BlockType.ROUND_DOWN && block.blocks) {
    formula = `ROUNDDOWN(${block.blocks
      .map((block) => flattenBlock(block, '', values, names))
      .join(' ')
      }, 0)`;
  }
  if (block.type === BlockType.ROUND_UP && block.blocks) {
    formula = `ROUNDUP(${block.blocks
      .map((block) => flattenBlock(block, '', values, names))
      .join(' ')
      }, 0)`;
  }
  if (block.type === BlockType.NOT && block.blocks) {
    formula = `NOT(${block.blocks
      .map((block) => flattenBlock(block, '', values, names))
      .join(' ')
      })`;
  }
  if (block.type === BlockType.FIELD && block.value) {
    if (typeof values[block.value as string] !== 'undefined') {
      formula = formatValue(values[block.value as string]);
    } else {
      if (insideIf) formula = 'NULL';
      else formula = 'NULL';
    }
  }
  if (block.type === BlockType.FIELD_NAME && block.value) {
    if (typeof names[block.value as string] !== 'undefined') {
      formula = names[block.value as string][lang];
    } else {
      if (insideIf) formula = 'NULL';
      else formula = 'NULL';
    }
  }
  if (block.type === BlockType.VALUE) {
    formula = (block.value || 0).toString();
  }
  if (block.type === BlockType.TEXT) {
    formula = quotes
      ? `'${(block.value || '').toString()}'`
      : `${(block.value || '').toString()} `;
  }
  if (block.type === BlockType.TEXT_I18N) {
    formula = `${(((block.value || {}) as I18nEntry)[lang] || '').toString()} `;
  }
  if (block.type === BlockType.TRUE) {
    if (insideIf) formula = 'TRUE';
    else formula = 'TRUE';
  }
  if (block.type === BlockType.DESIGN_X) {
    if (typeof values['DESIGN_X'] !== 'undefined') {
      formula = formatValue(values['DESIGN_X']);
    } else {
      if (insideIf) formula = 'NULL';
      else formula = 'NULL';
    }
  }
  if (block.type === BlockType.FALSE) {
    if (insideIf) formula = 'FALSE';
    else formula = 'FALSE';
  }
  if (block.type === BlockType.NULL) {
    if (insideIf) formula = 'NULL';
    else formula = 'NULL';
  }
  if (block.type === BlockType.DIVIDE) {
    formula = '/';
  }
  if (block.type === BlockType.ADD) {
    formula = '+';
  }
  if (block.type === BlockType.SUBSTRACT) {
    formula = '-';
  }
  if (block.type === BlockType.MULTIPLE) {
    formula = '*';
  }
  if (block.type === BlockType.EQUALS) {
    if (insideIf) formula = '=';
    else formula = '=';
  }
  if (block.type === BlockType.MORE_OR_EQUAL) {
    formula = '>=';
  }
  if (block.type === BlockType.MORE_THAN) {
    formula = '>';
  }
  if (block.type === BlockType.DIFFERENT) {
    formula = '<>';
  }
  if (block.type === BlockType.LESSER_OR_EQUAL) {
    formula = '<=';
  }
  if (block.type === BlockType.LESSER_THAN) {
    formula = '<';
  }

  if (block.type === BlockType.AND && block.blocks) {
    formula = `${formula} AND(${flattenBlock(
      block.blocks[0],
      '',
      values,
      names
    )
      }, ${flattenBlock(block.blocks[1], '', values, names)})`;
  }
  if (block.type === BlockType.OR && block.blocks) {
    formula = `${formula} OR(${flattenBlock(
      block.blocks[0],
      '',
      values,
      names
    )
      }, ${flattenBlock(block.blocks[1], '', values, names)})`;
  }
  return formula;
};

export const flattenBlockWithKeys = (
  block: Block,
  formula = '',
  values: I18nValues,
  lang: string
) => {
  if (
    block.type === BlockType.FIELD &&
    block.value &&
    values[block.value as string]
  ) {
    formula = `[${__('blocks.value')} ${values[block.value as string][lang]}]`;
  }
  if (
    block.type === BlockType.FIELD_NAME &&
    block.value &&
    values[block.value as string]
  ) {
    formula = `[${__('blocks.name')} ${values[block.value as string][lang]}]`;
  }
  if (block.type === BlockType.VALUE) {
    formula = (block.value || 0).toString();
  }
  if (block.type === BlockType.TEXT_I18N) {
    formula = `${(((block.value || {}) as I18nEntry)[lang] || '').toString()} `;
  }

  return formula;
};

export const flattenBlocks = (
  blocks: Block[],
  formula = '',
  values: any,
  names: I18nValues
) => {
  // console.log('flattenBlocks: ', blocks);
  if (Array.isArray(blocks)) {
    blocks.forEach((block) => {
      formula = `${formula} ${flattenBlock(block, formula, values, names)} `;
    });
  }
  return formula;
};

export const flattenBlocksWithoutQuotes = (
  blocks: Block[],
  formula = '',
  values: any,
  names: I18nValues,
  lang: string
) => {
  blocks.forEach((block) => {
    formula = `${formula} ${flattenBlock(
      block,
      formula,
      values,
      names,
      false,
      lang
    )
      } `;
  });
  return formula;
};

export const flattenBlocksWithKeys = (
  blocks: Block[],
  formula = '',
  values: I18nValues,
  lang: string
) => {
  blocks.forEach((block) => {
    formula = `${formula} ${flattenBlockWithKeys(
      block,
      formula,
      values,
      lang
    )
      } `;
  });
  return formula;
};
