import { Heading as ChakraHeading } from '@chakra-ui/layout';
import { useSelector } from 'react-redux';
import { I18nEntry } from '../../../../../../pol-met-back/types/i18n';
import { State } from '../../../../config/store';
import { __ } from '../../../../helpers/i18n';
import { getCurrentFromI18nEntry } from '../../../../utils/language';

interface Props {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  children?: React.ReactNode;
  text?: string | I18nEntry;
}

const Heading = ({ level = 2, children, text }: Props) => {
  const { current } = useSelector((state: State) => state.lang);

  let translated = '';

  if (typeof text === 'object') {
    translated = getCurrentFromI18nEntry(text, current);
  } else if (typeof text === 'string') {
    translated = __(text);
  }

  const content = (
    <>
      {translated || ''}
      {children}
    </>
  );

  switch (level) {
    case 1:
      return <h1>{content}</h1>;
    case 2:
      return <h2>{content}</h2>;
    case 3:
      return <h3>{content}</h3>;
    case 4:
      return <h4>{content}</h4>;
    case 5:
      return <h5>{content}</h5>;
    case 6:
      return <h6>{content}</h6>;
  }

  return null;
};

export { Heading };
