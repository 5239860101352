import { UserRole } from 'pol-met-types';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { store } from '../../config/store';

const RequireAuth = ({ children }: { children: React.ReactElement }) => {
    const location = useLocation();
    const user = store.getState().user.details;
    
    return user?.role === UserRole.Admin
        ? <>{children}</>
        : <Navigate
            to='/login'
            replace
            state={{
                path: location.pathname
            }}
        />
};

export default RequireAuth;