import React, { FC } from 'react';
import { Box, Divider } from '@chakra-ui/layout';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getSchema, schema } from '../../../formsSchemas/auth/emailPrompt';
import { useApi } from '../../../hooks/api/useApi';
import { successNotification } from '../../../helpers/handleNotifications';
import { UserMeDetails } from 'pol-met-types';
import { FormField } from '../../../components/FormField';
import { InputText } from '../../../components/FormField/InputText';
import { UnauthorizedLayout } from '../../../components/Layouts/Unauthorized';
import { __ } from '../../../helpers/i18n';
import { HeaderTitle } from '../../../components/Common/Header/HeaderTitle';
import { Button } from '../../../components/Common/Button';
import { Text } from '../../../components/Common/Text';
import { StackColumn } from '../../../components/Common/Auth/StackColumn';
import { LogoBoxAuth } from '../../../components/Common/Auth/LogoBoxAuth';
import { FlexAuth } from '../../../components/Common/Auth/FlexAuth';
import { useNavigate } from 'react-router-dom';
import { TextLink } from '../../../components/Common/Text/TextLink';

interface ResetPassRequest {
  email: string;
}

interface LoggedUserResponse {
  user: UserMeDetails;
}

export const EmailPrompt: FC = () => {
  const navigate = useNavigate();
  const methods = useForm<ResetPassRequest>({
    mode: 'onChange',
    // resolver: yupResolver(schema),
    resolver: yupResolver(getSchema()),
  });
  const { post, inProgress } = useApi({ methods });

  const submit = async (data: ResetPassRequest) => {
    const res = await post<LoggedUserResponse, ResetPassRequest>(
      '/user/reset-pass-request',
      data
    );
    // TODO: Find better solution
    if (res === null) {
      successNotification(__('application.resetEmailToastSuccess'));
      navigate('/login');
    }
  };

  return (
    <UnauthorizedLayout isLoading={inProgress}>
      <FlexAuth>
        <LogoBoxAuth />
        <Divider
          orientation={'vertical'}
          p="0 2rem"
          height={['0', '0', '280px', '300px']}
        />
        <Box width={['200px', '300px', '400px', '400px']}>
          <HeaderTitle text="application.forgotPassword" />
          <Text
            text="application.resetPasswordParagraph"
            mb={['1rem', '1.2rem', '1.4rem', '1.6rem']}
            width="auto"
          />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submit)}>
              <StackColumn>
                <FormField labelTxt="labels.email" name="email" width="100%">
                  <InputText placeholder="placeholders.enterData" />
                </FormField>
                <Box textAlign="right" width="100%">
                  <Button
                    type="submit"
                    variant="main"
                    text="action.next"
                    disabled={!methods.formState.isValid || inProgress}
                  />
                </Box>
                <Box width="100%" textAlign="left">
                  <TextLink
                    text={'application.backToLogin'}
                    to="/login"
                    color="main"
                    mb={0}
                  />
                </Box>
              </StackColumn>
            </form>
          </FormProvider>
        </Box>
      </FlexAuth>
    </UnauthorizedLayout>
  );
};
