import { Block, SelectOptionI18n } from '..';
import { Recipe } from '../../src/recipe/entities/recipe.entity';
import { I18nEntry } from '../i18n';
import { RecipeDetails } from './recipe';

export interface RecipeFieldEntity {
	id: string;
	name: I18nEntry;
	type: RecipeFieldType;
	position: number;
	condition?: Block[];
	recipe: Recipe;
	options?: SelectOptionI18n[];
}

export interface OfferField {
	id: string;
	type: RecipeFieldType;
	position: number;
	name: I18nEntry;
	value: string | number | boolean | null;
}

export interface RecipeFieldDetails {
	id: string;
	name: I18nEntry;
	type: RecipeFieldType;
	position: number;
	condition?: Block[];
	options?: SelectOptionI18n[];
}

export interface CreateRecipeFieldReq {
	name: I18nEntry;
	type: RecipeFieldType;
	position: number;
	condition?: Block[];
	options?: SelectOptionI18n[];
	recipe: string;
}

export enum RecipeFieldType {
	TEXT,
	NUMBER,
	CHECKBOX,
	RADIOBUTTONS,
	SELECT,
}
