import { FC } from 'react';
import { FormLabel as FormLabelChakra, FormLabelProps } from "@chakra-ui/react";
import { __ } from '../../../helpers/i18n';
import { formFieldLabelFontSizes } from '../../../config/defaultConfigProps';
import { Text } from '../Text';

interface Props extends FormLabelProps {
  labelTxt?: string;
  name?: string;
  isRequired?: boolean;
}

export const LabelForm: FC<Props> = ({ labelTxt, name, isRequired, ...props }) => {

  return (
    <FormLabelChakra whiteSpace='pre-line' htmlFor={name} m='0' p='0' color='text' fontSize={formFieldLabelFontSizes}>
      {labelTxt && __(labelTxt)}
      {isRequired && (
        <Text as='span' color='blocked'>
          *
        </Text>
      )}
    </FormLabelChakra>
  );
};