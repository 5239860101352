import '@fontsource/roboto/latin.css';

import React, { FC, Suspense, useState, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider, Center } from '@chakra-ui/react';
import { Provider } from 'react-redux';
// CONFIGS UTILS HELPERS
import { theme } from '../../config/theme';
import { setWindowState } from '../../utils/windowState';
import { i18n } from '../../helpers/i18n';
import { store } from '../../config/store';
import { getClientLanguage } from '../../utils/language';
// COMPONENTS
import ErrorBoundary from '../ErrorBoundary';
import PageNotFound from '../PageNotFound';
import RequireAuth from '../../route/RequireAuth';
import { OnlineContent } from '../Common/OnlineContent';
import { OfflineContent } from '../Common/OfflineContent';
import { AppOffline } from '../Common/OfflineContent/AppOffline';
import { AppSpinner } from '../Common/AppSpinner';
import { useStoreWithInitializer } from '../../hooks/storeHooks';
import { getUserDetails } from '../../slices/userSlice';
import { EmailPrompt } from '../../views/RestorePass/EmailPrompt';
import { RestorePass } from '../../views/RestorePass/RestorePass';

// LAZY LOADING COMPONENTS
const Login = React.lazy(() => import('../../views/Login'));
const Admin = React.lazy(() => import('../../views/Admin'));
const ClientOfferDetails = React.lazy(
  () => import('../../views/Client/Offer/Details')
);

export const App: FC = () => {
  // GET USER DETAILS
  const { isLoaded } = useStoreWithInitializer(
    ({ user }) => user,
    loadUserDetails
  );
  // APP LANGUAGE
  const [i18nLoaded, setI18nLoaded] = useState(i18n.loaded);
  const reloadActiveTranslations = () => {
    setI18nLoaded(true);
  };
  useLayoutEffect(() => {
    const lang = store.getState().lang.current;
    const clientLanguage = getClientLanguage();
    if (lang) {
      i18n.load(lang, reloadActiveTranslations);
    } else {
      i18n.load(clientLanguage, reloadActiveTranslations);
    }
  }, [setI18nLoaded]);

  return i18nLoaded ? (
    <Provider store={store}>
      <ErrorBoundary>
        <ChakraProvider theme={theme} resetCSS>
          <OnlineContent>
            <Router>
              <Suspense fallback={<AppSpinner fullScreen />}>
                {isLoaded ? <Routing /> : null}
              </Suspense>
            </Router>
          </OnlineContent>

          <OfflineContent>
            <AppOffline />
          </OfflineContent>
        </ChakraProvider>
      </ErrorBoundary>
    </Provider>
  ) : (
    <Center>
      <AppSpinner />
    </Center>
  );
};

export const Routing: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="/panel/*"
        element={
          <RequireAuth>
            <Admin />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/restore-pass/email-prompt" element={<EmailPrompt />} />
      <Route
        path="/restore-pass/:userId/:updateToken"
        element={<RestorePass />}
      />
      <Route
        path="/offer/:offerId/:accessToken"
        element={<ClientOfferDetails />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

function loadUserDetails() {
  store.dispatch(getUserDetails());
}

setWindowState();
