import { FC } from 'react';
import { useFormContext, get } from 'react-hook-form';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  BoxProps,
  Flex,
  Box,
} from '@chakra-ui/react';
import { SerializedStyles } from '@emotion/serialize';
import { FormFieldProvider } from '../../hooks/form/useFormFieldContext';
import { __ } from '../../helpers/i18n';
import { Text } from '../Common/Text';
import { FormFieldContextType } from '../../types/forms';
import {
  formFieldErrorMessageFontSizes,
  formFieldLabelFontSizes,
} from '../../config/defaultConfigProps';
import { LabelForm } from '../Common/Label/FormLabel';

interface Props extends FormFieldContextType, BoxProps {
  labelTxt?: string;
  helperTxt?: string;
  errorTxt?: string | null;
  isDisabled?: boolean;
  formFieldCustomCSS?: SerializedStyles;
  errorBoxHeight?: string | string[];
}

export const FormField: FC<Props> = ({
  name,
  isRequired,
  labelTxt,
  helperTxt,
  errorTxt,
  placeholder,
  children,
  isDisabled,
  id,
  formFieldCustomCSS,
  errorBoxHeight,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const { justifyContent, flexDirection, ...rest } = props;

  return (
    <Flex
      justifyContent={justifyContent ?? 'flex-end'}
      flexDirection={flexDirection ?? 'column'}
      {...rest}
    >
      <FormFieldProvider value={{ name, placeholder, id }}>
        <FormControl
          isDisabled={isDisabled}
          isInvalid={get(errors, name)?.message ?? errorTxt}
          css={formFieldCustomCSS}
        >
          {labelTxt && (
            <LabelForm
              labelTxt={labelTxt}
              isRequired={isRequired}
              name={name}
            />
          )}
          {children}
          <Box
            height={errorBoxHeight ?? ['14px', '15px', '17px', '18px']}
            role="status"
          >
            {helperTxt && !errors?.[name] && (
              <FormHelperText mt={0} id={name}>
                {helperTxt}
              </FormHelperText>
            )}
            <FormErrorMessage
              role="alert"
              mt={0}
              fontSize={formFieldErrorMessageFontSizes}
            >
              {get(errors, name)?.message ?? (errorTxt && __(errorTxt))}
            </FormErrorMessage>
          </Box>
        </FormControl>
      </FormFieldProvider>
    </Flex>
  );
};
