import { extendTheme } from "@chakra-ui/react";
import { borderRadius, buttonFontSizes, headerBaseFontSizes, headerTabFontSizes, mainRGBA, navBtnBg } from "./defaultConfigProps";
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { css } from '@emotion/css';
import { StepsStyleConfig as Steps } from '../components/Common/Stepper/theme';
import { formFieldLabelFontSizes } from './defaultConfigProps';
import { StylesConfig } from 'react-select';

// CHAKRA BREAKPOINTS
const breakpoints = createBreakpoints({
  sm: '30em',
  md: '49em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
})

// CHAKRA SIZES CONFIG
const sizes = {
  xs: '1.2rem',
  sm: '1.4rem',
  md: '1.55rem',
  lg: '1.8rem',
  xl: '2rem',
  '2xl': '2.4rem',
  '3xl': '3rem',
  '4xl': '3.6rem',
  '5xl': '4.8rem',
  '6xl': '6rem',
  '7xl': '7.2rem',
  '8xl': '9.6rem',
  '9xl': '12.8rem',
};

// CHAKRA COLORS CONFIG
const colors = {
  transparent: 'transparent',
  main: '#0B82D1',
  mainBg: '#ffffff',

  activeFilterBg: '#DAECF8',
  backgroundApp: '#F8FAFB',
  grayBg: '#F8F9FA',
  redBtn: '#E53E3E',
  border: '#E1E3E6',
  placeholder: '#A0AEC0',
  selectPlaceholder: '#A0AEC0',
  selectMenuBorder: '#595959',
  dropdownIcon: '#5C5D5F',
  defaultSelectBlue: '#3297FD',
  paginationText: '#6B7889',
  text: '#3A3D41',
  headerText: '#0C0B0E',
  btnText: '#0C0B0E',
  paragraph: '#6B7889',
  lightBlue: '#31A9F7',
  navBtnBg: navBtnBg,
  tableRow: '#ffffff',
  tableRowHover: '#EFF0F1',
  tableHeader: '#6B7889',
  active: '#31A9F7',
  stepsSheme: {
    50: '#EDFDFD',
    100: '#C4F1F9',
    200: '#9DECF9',
    300: '#76E4F7',
    400: '#0BC5EA',
    500: '#01AAA5',
    600: '#00A3C4',
    700: '#0987A0',
    800: '#086F83',
    900: '#065666',
  },
};

// CHAKRA COMPONENTS CONFIG
const components = {
  Steps,
  Button: {
    baseStyle: {
      fontWeight: 'normal',
      borderRadius,
      py: '2rem',
      px: '3rem',
      fontSize: buttonFontSizes,
      lineHeight: 'initial',
      _hover: {
        opacity: '0.8',
        _disabled: {
          opacity: '0.2',
        },
      },
    },
    variants: {
      main: () => ({
        bg: 'main',
        color: '#ffffff',
        py: '2rem',
        px: '3rem',
        _hover: {
          bg: 'main',
          _disabled: {
            background: 'main',
            _disabled: {
              background: 'main',
            },
          },
        },
      }),
      danger: () => ({
        bg: 'redBtn',
        color: '#ffffff',
        py: '2rem',
        px: '3rem',
        _hover: {
          bg: 'redBtn',
          _disabled: {
            background: 'redBtn',
            _disabled: {
              background: 'redBtn',
            },
          },
        },
      }),
      redTable: () => ({
        color: 'redBtn',
        py: '2rem',
        px: '3rem',
        _hover: {
          bg: 'redBtn',
          color: '#ffffff',
          _disabled: {
            background: 'redBtn',
            _disabled: {
              background: 'redBtn',
            },
          },
        },
      }),
      mainTable: () => ({
        color: 'main',
        py: '2rem',
        px: '3rem',
        _hover: {
          bg: 'main',
          color: '#ffffff',
          _disabled: {
            background: 'main',
            _disabled: {
              background: 'main',
            },
          },
        },
      }),
      text: () => ({
        bg: 'transparent',
        color: 'btnText',
        py: '0',
        px: '0',
        _hover: {
          bg: 'transparent',
          color: 'lightBlue',
          _disabled: {
            background: 'transparent',
            _disabled: {
              background: 'transparent',
            },
          },
        },
      }),
      textBlue: () => ({
        bg: 'transparent',
        color: 'main',
        py: '0',
        px: '0',
        height: 'auto',
        _hover: {
          bg: 'transparent',
          color: 'lightBlue',
          _disabled: {
            background: 'transparent',
            _disabled: {
              background: 'transparent',
            },
          },
        },
      }),
      textRed: () => ({
        bg: 'transparent',
        color: 'red',
        py: '0',
        px: '0',
        height: 'auto',
        _hover: {
          bg: 'transparent',
          color: 'lightRed',
          _disabled: {
            background: 'transparent',
            _disabled: {
              background: 'transparent',
            },
          },
        },
      }),
      nav: () => ({
        bg: 'transparent',
        color: 'btnText',
        py: '2rem',
        px: '3rem',
        justifyContent: { base: 'center', md: 'flex-start' },
        _hover: {
          bg: 'navBtnBg',
          color: 'btnText',
          opacity: '1',
          _disabled: {
            background: 'transparent',
            _disabled: {
              background: 'transparent',
            },
          },
        },
      }),
      goToPage: () => ({
        color: 'btnText',
        background: 'mainBg',
        border: '1px solid',
        borderColor: 'border',
        py: '2rem',
        px: '3rem',
        _hover: {
          background: 'main',
          // opacity: 1,
          color: 'mainBg',
          _disabled: {
            background: 'main',
          },
        },
        _disabled: {
          border: '1px solid',
          borderColor: 'border',
          background: 'main',
          color: 'mainBg',
          opacity: 1,
        }
      }),
      goTo: () => ({
        color: 'btnText',
        background: 'mainBg',
        border: '1px solid',
        borderColor: 'border',
        py: '2rem',
        px: '3rem',
        _hover: {
          background: 'main',
          color: 'mainBg',
          _disabled: {
            background: 'main',
          },
        },
      }),
      table: () => ({
        bg: 'transparent',
        mx: '0.5rem',
        _hover: {
          bg: mainRGBA(0.1),
          opacity: '1',
          _disabled: {
            background: 'transparent',
            _disabled: {
              background: 'transparent',
            },
          },
        },
      }),
      graphPrimary: () => ({
        border: '1px solid',
        color: '#fff',
        borderColor: 'border',
        bg: 'main',
        m: '0.2rem',
        py: '0.4rem',
        px: '0.8rem',
        fontSize: '1.2rem',
        _hover: {
          bg: mainRGBA(0.1),
          opacity: '1',
        }
      }),
      graphSecondary: () => ({
        border: '1px solid',
        borderColor: 'border',
        bg: 'lightBlue',
        m: '0.2rem',
        py: '0.4rem',
        px: '0.8rem',
        fontSize: '1.2rem',
        _hover: {
          bg: mainRGBA(0.1),
          opacity: '1',
        }
      }),
      graphTernary: () => ({
        border: '1px solid',
        borderColor: 'border',
        bg: 'transparent',
        m: '0.2rem',
        py: '0.4rem',
        px: '0.8rem',
        fontSize: '1.2rem',
        _hover: {
          bg: mainRGBA(0.1),
          opacity: '1',
        }
      }),
    },
  },
  Input: {
    baseStyle: {
      field: {
        borderRadius,
        '*::placeholder': {
          color: 'placeholder'
        }
      },
    },
    variants: {
      globalFilter: () => ({
        bg: 'mainBg',
        border: 'border',
        borderRadius,
        color: 'paragraph',
      })
    }
  },
  Select: {
    baseStyle: {
      field: {
        // color: 'placeholder',
      },
      sx: {
        "& option[value='']": {
          color: 'placeholder'
        }
      },
    },
  },
  Checkbox: {
    variants: {
      main: {
        label: {
          fontSize: formFieldLabelFontSizes
        },
        control: {
          zoom: [1.2, 1.5, 1.6, 1.7],
          border: '0.1rem solid',
        },
      }
    }
  },
  Heading: {
    baseStyle: {
      p: 0,
      color: 'headerText',
    },
    variants: {
      main: {

      },
      tab: {
        fontSize: headerTabFontSizes,
        p: '0.2rem 0.5rem',
      },
    }
  },
}

const styles = {
  global: {
    html: {
      fontSize: '62.5%',
      height: '100%',
    },
    body: {
      fontSize: '1.6rem',
      backgroundColor: 'backgroundApp',
      overflow: 'hidden',
      height: '100%',
    },
    a: {
      _hover: {
        textDecoration: 'none !important',
      },
    },
    textarea: {
      border: `1px solid ${colors.border}`
    },
    input: {
      border: `1px solid ${colors.border}`
    },
    '*::placeholder': {
      color: colors.selectPlaceholder,
    },
    canvas: {
      maxWidth: '100%'
    },
    h1: {
      fontSize: ['1.6rem', '1.8rem', '2rem', '2.4rem'],
      marginTop: 2,
      margnBottom: 2,
    },
    h2: {
      fontSize: ['1.5rem', '1.7rem', '1.9rem', '2.1rem'],
      marginTop: 2,
      margnBottom: 2,
      fontWeight: 700,
    },
    h3: {
      fontSize: ['1.4rem', '1.6rem', '1.75rem', '2rem'],
      marginTop: 2,
      margnBottom: 2,
    },
    h4: {
      fontSize: ['1.3rem', '1.4rem', '1.5rem', '1.6rem'],
      marginTop: 2,
      margnBottom: 2,
      borderLeft: '5px solid',
      borderColor: 'main',
      borderRadius: 2,
      paddingLeft: 2,
      fontWeight: 700
    },
    h5: {
      fontSize: ['1.3rem', '1.4rem', '1.5rem', '1.6rem'],
      marginTop: 1,
      margnBottom: 1,
    },
    h6: {
      fontSize: ['1.3rem', '1.4rem', '1.5rem', '1.6rem'],
      margin: 0
    },
    '#root': {
      height: '100%',
      overflow: 'auto'
    }
  },
};

export const theme = extendTheme({
  colors,
  components,
  styles,
  fonts: {
    heading: 'Roboto, sans-serif',
    body: 'Roboto, sans-serif',
  },
  fontSizes: sizes,
  sizes,
  space: sizes,
  shadows: { outline: 'none' },
  breakpoints,

});

export const customStylesForSelect: StylesConfig = {
  control: (styles) => ({
    ...styles,
    minHeight: 'auto',
    height: 'auto',
    border: `1px solid ${colors.border}`,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: 0,
    width: '15px',
    paddingRight: '4px',
    color: colors.dropdownIcon,
  }),
  option: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    padding: '0 8px',
    borderColor: colors.border,
    cursor: 'pointer',
    backgroundColor: isDisabled
      ? undefined
      : isSelected
        ? colors.defaultSelectBlue
        : isFocused
          ? colors.defaultSelectBlue
          : undefined,
    color: isDisabled
      ? undefined
      : isSelected
        ? '#000'
        : isFocused
          ? colors.mainBg
          : undefined,
  }),
  menu: (styles) => ({
    ...styles,
    margin: 0,
    padding: 0,
    borderTop: 'none',
    borderRadius: '0',
    border: `1px solid ${colors.selectMenuBorder}`,
  }),
  menuList: (styles) => ({
    ...styles,
    margin: 0,
    padding: 0,
  }),
  noOptionsMessage: () => ({
    margin: 0,
    padding: '0 12px',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: colors.selectPlaceholder,
  })
};

export const customStylesForSelectSearchable: StylesConfig = {
  control: (styles) => ({
    ...styles,
    minHeight: 'auto',
    height: 'auto',
    border: `1px solid ${colors.border}`
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: 0,
    width: '15px',
    paddingRight: '4px',
    color: colors.dropdownIcon,
  }),
  option: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    padding: '0 8px',
    borderColor: colors.border,
    cursor: 'pointer',
    backgroundColor: isDisabled
      ? undefined
      : isSelected
        ? colors.defaultSelectBlue
        : isFocused
          ? colors.defaultSelectBlue
          : undefined,
    color: isDisabled
      ? undefined
      : isSelected
        ? '#000'
        : isFocused
          ? colors.mainBg
          : undefined,
  }),
  menu: (styles) => ({
    ...styles,
    margin: 0,
    padding: 0,
    borderTop: 'none',
    borderRadius: '0',
    border: `1px solid ${colors.selectMenuBorder}`,
  }),
  menuList: (styles) => ({
    ...styles,
    margin: 0,
    padding: 0,
  }),
  noOptionsMessage: () => ({
    margin: 0,
    padding: '0 12px',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: colors.selectPlaceholder,
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '0.5px 8px',
  }),
};

export const disabledInput = css`
  opacity: 0.4;
  cursor: not-allowed;
  div {
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const customPrefixStylesForSelectSearchable = css`
  font-size: 1.6rem;

  @media screen and (max-width: ${breakpoints.lg}) {
    font-size: 1.4rem;

    div.custom-theme-styles__input-container {
        padding: 1px 0;
      }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 1.2rem;

    div.custom-theme-styles__input-container {
      padding: 0;
    }

    div.custom-theme-styles__value-container {
      padding: 1px 8px;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1rem;

    div.custom-theme-styles__value-container {
        padding: 2px 8px;
      }
  }
`

export const customPrefixStylesForSelect = css`
  font-size: 1.6rem;

  div.custom-theme-styles__value-container {
    padding: 4.5px 8px;
}

  @media screen and (max-width: ${breakpoints.lg}) {
    font-size: 1.4rem;

    div.custom-theme-styles__input-container {
        padding: 1px 0;
      }

    div.custom-theme-styles__value-container {
        padding: 3.5px 8px;
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 1.2rem;

    div.custom-theme-styles__input-container {
      padding: 0;
    }

    div.custom-theme-styles__value-container {
        padding: 3px 8px;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1rem;

    div.custom-theme-styles__value-container {
        padding: 4px 8px;
      }
  }
`