import { FC } from 'react';
import { Flex as FlexChakra, FlexProps } from '@chakra-ui/layout';
import { borderRadius } from '../../../../config/defaultConfigProps';

export const FlexAuth: FC<FlexProps> = ({ children, ...props }) => (
    <FlexChakra
        flexDir={['column', 'column', 'row', 'row']}
        alignItems='center'
        justifyContent='center'
        backgroundColor='mainBg'
        p={['2rem', '2rem', '2rem 2rem 2rem 0', '2rem 2rem 2rem 0']}
        border='1px'
        borderColor='border'
        borderStyle='solid'
        borderRadius={borderRadius}
        {...props}
    >
        {children}
    </FlexChakra>
);