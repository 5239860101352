import { FC } from 'react';
import { Button as ChakraButton, Link } from '@chakra-ui/react';
import { __ } from '../../../helpers/i18n';

interface Props {
  variant?:
    | 'main'
    | 'danger'
    | 'success'
    | 'graphPrimary'
    | 'graphSecondary'
    | 'graphTernary'
    | 'textOnly';
  text?: string;
  to?: string | null;
  blank?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  type?: 'button' | 'submit';
  disabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
}

export const Button: FC<Props> = ({
  type = 'button',
  text,
  onClick,
  to,
  blank,
  children,
  variant,
  disabled,
  isLoading,
  loadingText,
}) => {
  const content = (
    <ChakraButton
      as={to ? 'div' : 'button'}
      variant={variant}
      isLoading={isLoading}
      loadingText={loadingText}
      type={type}
      disabled={disabled}
      onClick={onClick}
      margin={1}
    >
      {text && __(text)}
      {children}
    </ChakraButton>
  );
  return to && !blank && !disabled ? <Link href={to}>{content}</Link> : content;
};
