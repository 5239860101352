// MAIN APP CONFIG FILE
const AppConfig = {
    LANGUAGES: ['pl'],
    DEFAULT_LANG: 'pl',
    DATE_ISO8601_FORMAT: 'yyyy-MM-dd',
    DATE_FORMAT: 'dd-MM-yyyy',
    DATETIME_FORMAT: 'dd-MM-yyyy HH:mm',
    FULL_DATE_FORMAT: 'EEEE, dd MMMM yyyy',
    TIME_FORMAT: 'HH:mm',
    REACT_DATE_PICKER_DATE: '00-00-0000',
    REACT_DATE_PICKER_DATE_AND_TIME: '00-00-0000 00-00',
    DEFAULT_MARKUP: 0,
};

export default AppConfig;