export enum ApiErrorCode {
	OtherError,
	UserNotFound,
	NoSuchObjectWithThisId,
	InvalidLang,
	ObjectNotUnique,
	FormValidationError,
	InvalidTokenError,
	InvalidTokenNotForThisUser,
	InvalidTokenExpired,
	InvalidLoginData,
	ClientNotFound,
	NeedAtLeastOneContact,
	NoMainContact,
	OnlyOneMainContactAllowed,
	LanguageNotSupported,
	ProductNotFound,
	FileIsTooLarge,
	ImageIsRequired,
	ForbiddenFileFormat,
	CantAssignSameAsSubproduct,
	NeedAtLeastOneOption,
	RecipeSectionNotFound,
	OfferNotFound,
	RecipeDesignNotFound,
	RecipeDesignSeriesNotFound,
	CantAssignSameAsParentCategory,
	CannotDeleteParentCategory,
	CategoryWithThisNumberAlreadyExist,
	CannotDeleteUnitBecauseUsedInAnotherModule,
	CannotDeleteCategoryBecauseUsedInAnotherModule,
	CannotDeleteComponentUsedInRecipe,
}
