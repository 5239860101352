import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import BasicModal from '../../../BasicModal';
import * as yup from 'yup';
import { __ } from '../../../../../../helpers/i18n';
import { FormField } from '../../../../../FormField';
import { FormStack } from '../../../../../FormField/FormStack';
import { InputNumber } from '../../../../../FormField/InputNumber';
import { BoxForm } from '../../../../Box/BoxForm';
import { Button } from '../../../../Button';
import { HStack } from '@chakra-ui/react';
import { ConfiguratorModalProductForm } from '../../../../../FormContent/Offer/FormCalculation/Modal/Product';
import { ConfiguratorModalServiceForm } from '../../../../../FormContent/Offer/FormCalculation/Modal/Service';
import { ConfiguratorModalComponentForm } from '../../../../../FormContent/Offer/FormCalculation/Modal/Component';
import { store } from '../../../../../../config/store';
import { addProductToOffer, addServiceToOffer, addComponentToOffer } from '../../../../../../slices/configurator/visualisationSlice';
import { ProductDetails, ServiceDetails, ComponentDetails, RecipeGroupEntity } from 'pol-met-types';
import ConfiguratorOfferProduct from './Product';
import ConfiguratorOfferService from './Service';
import ConfiguratorOfferComponent from './Component';

export enum CONFIGURATOR_OFFER_MODAL_TYPES {
  PRODUCTS,
  SERVICES,
  COMPONENTS,
}

interface Props {
  handleClose: () => void;
  type: CONFIGURATOR_OFFER_MODAL_TYPES | null;
  group: RecipeGroupEntity;
}

const getTitle = (type: CONFIGURATOR_OFFER_MODAL_TYPES | null) => {
  switch (type) {
    case CONFIGURATOR_OFFER_MODAL_TYPES.PRODUCTS: return __('labels.addProduct');
    case CONFIGURATOR_OFFER_MODAL_TYPES.SERVICES: return __('labels.addService');
    case CONFIGURATOR_OFFER_MODAL_TYPES.COMPONENTS: return __('labels.addComponent');
    default: return '';
  }
}

const getSchema = (type: CONFIGURATOR_OFFER_MODAL_TYPES | null) => {
  switch (type) {
    case CONFIGURATOR_OFFER_MODAL_TYPES.SERVICES: return yup.object().shape({
      service: yup.object().required(__('error.fieldIsRequired')),
      quantity: yup.number().nullable().required(__('error.fieldIsRequired')),
    });
    case CONFIGURATOR_OFFER_MODAL_TYPES.COMPONENTS: return yup.object().shape({
      component: yup.object().required(__('error.fieldIsRequired')),
      quantity: yup.number().nullable().required(__('error.fieldIsRequired')),
    });
    default: return yup.object().shape({
      product: yup.object().required(__('error.fieldIsRequired')),
      quantity: yup.number().nullable().required(__('error.fieldIsRequired')),
    });
  }
};

const getSelectForm = (type: CONFIGURATOR_OFFER_MODAL_TYPES | null) => {
  switch (type) {
    case CONFIGURATOR_OFFER_MODAL_TYPES.PRODUCTS: return <ConfiguratorModalProductForm />;
    case CONFIGURATOR_OFFER_MODAL_TYPES.SERVICES: return <ConfiguratorModalServiceForm />;
    case CONFIGURATOR_OFFER_MODAL_TYPES.COMPONENTS: return <ConfiguratorModalComponentForm />;
    default: return '';
  }
};

const ConfiguratorOfferModal: FC<Props> = ({ handleClose, type, group }) => {
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(getSchema(type)),
    defaultValues: {
      quantity: 1,
    }
  });

  const submit = (data: any) => {
    if (type === CONFIGURATOR_OFFER_MODAL_TYPES.PRODUCTS) store.dispatch(addProductToOffer({
      quantity: data.quantity,
      product: data.product,
      group,
    }));
    if (type === CONFIGURATOR_OFFER_MODAL_TYPES.SERVICES) store.dispatch(addServiceToOffer({
      quantity: data.quantity,
      service: data.service,
      group,
    }));
    if (type === CONFIGURATOR_OFFER_MODAL_TYPES.COMPONENTS) store.dispatch(addComponentToOffer({
      quantity: data.quantity,
      component: data.component,
      group,
    }));
    methods.reset();
    handleClose();
  };

  const handleCloseBtn = (data?: any) => {
    methods.reset();
    handleClose();
  };

  const checkIsOpen = () => {
    if (
      type === CONFIGURATOR_OFFER_MODAL_TYPES.PRODUCTS
      || type === CONFIGURATOR_OFFER_MODAL_TYPES.SERVICES
      || type === CONFIGURATOR_OFFER_MODAL_TYPES.COMPONENTS
    ) return true;
    return false;
  };

  const getContent = () => {
    switch (type) {
      case CONFIGURATOR_OFFER_MODAL_TYPES.PRODUCTS: return <ConfiguratorOfferProduct handleClose={handleClose} group={group} />;
      case CONFIGURATOR_OFFER_MODAL_TYPES.SERVICES: return <ConfiguratorOfferService handleClose={handleClose} group={group} />;
      case CONFIGURATOR_OFFER_MODAL_TYPES.COMPONENTS: return <ConfiguratorOfferComponent handleClose={handleClose} group={group} />;
      default: return '';
    }
  }

  return (
    <BasicModal
      isOpen={checkIsOpen()}
      title={getTitle(type)}
      whiteSpace='pre-line'
      onClose={() => handleCloseBtn(methods.getValues())}
      closeOnOverlayClick={false}
    >
      {getContent()}
      {/* <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submit)}>
          <BoxForm>
            {getSelectForm(type)}
            <FormStack>
              <FormField labelTxt='labels.quantity' name='quantity' width={'full'}>
                <InputNumber withStepper min={0} placeholder='placeholders.enterData' />
              </FormField>
            </FormStack>
          </BoxForm>

          <HStack spacing='1rem' justifyContent={'center'} mb='1rem'>
            <Button type='submit' variant='main' disabled={!methods?.formState.isValid}>
              {__('action.submit')}
            </Button>
            <Button type='button' variant='main' onClick={() => handleCloseBtn()}>
              {__('action.cancel')}
            </Button>
          </HStack>
        </form>
      </FormProvider> */}
    </BasicModal>
  );
};

export default ConfiguratorOfferModal;
