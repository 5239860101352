import { CategoryDetails } from '../category';
import { I18nEntry } from '../i18n';
import { UnitDetails } from '../unit';

export enum ProductType {
	Standard,
	Profile,
}

export interface ProductEntity {
	id: string;
	name: I18nEntry;
	description: I18nEntry;
	unit: UnitDetails;
	category: CategoryDetails;
	producer: string;
	position: number;
	buyPrice: number;
	region?: string;
	city?: string;
	markUp: number;
	currentPrice: number;
	isAdditional?: boolean;
}

export interface ProductDetails {
	id: string;
	name: I18nEntry;
	description: I18nEntry;
	position: number;
	unit: UnitDetails;
	category: CategoryDetails;
	producer: string;
	buyPrice: number;
	region?: string;
	city?: string;
	markUp: number;
	currentPrice: number;
}

export interface ProductRequest {
	name: I18nEntry;
	description: I18nEntry;
	unit: string;
	category: string;
	producer: string;
	buyPrice: number;
	position: number;
	region?: string;
	city?: string;
	markUp: number;
	currentPrice: number;
}
