
import { ApiErrorCode } from 'pol-met-types';
import { __ } from '../helpers/i18n';

const translate = (key: string): string => __(`error.${key}`);

export const getErrorMessage = (error: ApiErrorCode): string => {
  switch (error) {
    case ApiErrorCode.OtherError:
      return translate('occurred');
    case ApiErrorCode.UserNotFound:
      return translate('userNotFound');
    case ApiErrorCode.NoSuchObjectWithThisId:
      return translate('noSuchUserWithThisId');
    case ApiErrorCode.InvalidLang:
      return translate('invalidLang');
    case ApiErrorCode.ObjectNotUnique:
      return translate('objectNotUnique');
    case ApiErrorCode.FormValidationError:
      return translate('formValidationError');
    case ApiErrorCode.ProductNotFound:
      return translate('productNotFound');
    case ApiErrorCode.InvalidTokenError:
      return translate('invalidTokenError');
    case ApiErrorCode.InvalidTokenNotForThisUser:
      return translate('invalidTokenNotForThisUser');
    case ApiErrorCode.InvalidTokenExpired:
      return translate('invalidTokenExpired');
    case ApiErrorCode.InvalidLoginData:
      return translate('invalidLoginData');
    case ApiErrorCode.ClientNotFound:
      return translate('clientNotFound');
    case ApiErrorCode.NeedAtLeastOneContact:
      return translate('needAtLeastOneContact');
    case ApiErrorCode.NoMainContact:
      return translate('noMainContact');
    case ApiErrorCode.OnlyOneMainContactAllowed:
      return translate('onlyOneMainContactAllowed');
    case ApiErrorCode.FileIsTooLarge:
      return translate('fileIsTooLarge');
    case ApiErrorCode.ImageIsRequired:
      return translate('imageIsRequired');
    case ApiErrorCode.ForbiddenFileFormat:
      return translate('forbiddenFileFormat');
    case ApiErrorCode.CantAssignSameAsSubproduct:
      return translate('cantAssignSameAsSubproduct');
    case ApiErrorCode.CantAssignSameAsParentCategory:
      return translate('cantAssignSameAsParentCategory');
    case ApiErrorCode.CannotDeleteParentCategory:
      return translate('cannotDeleteParentCategory');
    case ApiErrorCode.CategoryWithThisNumberAlreadyExist:
      return translate('categoryWithThisNumberAllreadyExist');
    case ApiErrorCode.CannotDeleteUnitBecauseUsedInAnotherModule:
      return translate('cannotDeleteUnitBecauseUsedInAnotherModule');
    case ApiErrorCode.CannotDeleteCategoryBecauseUsedInAnotherModule:
      return translate('cannotDeleteCategoryBecauseUsedInAnotherModule');
    case ApiErrorCode.CannotDeleteComponentUsedInRecipe:
      return translate('cannotDeleteComponentUsedInRecipe')
    default:
      return translate('occurred');
  }
};