import { UserMeDetails, UserRole } from 'pol-met-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { State } from '../config/store';

interface UseUsers {
    isAdmin: () => boolean;
    hasAccess: (toCheck: UserMeDetails[keyof UserMeDetails], parameter: keyof UserMeDetails) => boolean;
    redirectAfterLogging: (providedUser?: UserMeDetails) => void;
}


export const useUsers = (): UseUsers => {
    const {
        details
    } = useSelector((state: State) => state.user);
    const navigate = useNavigate();

    const hasAccess = (toCheck: UserMeDetails[keyof UserMeDetails], parameter: keyof UserMeDetails) => {
        if (!details) return false;
        return toCheck === details[parameter];
    };

    const isAdmin = () => hasAccess(UserRole.Admin, 'role');

    const redirectAfterLogging = (providedUser?: UserMeDetails) => {
        if (!providedUser) return;
        if (isAdmin()) {
            navigate('/panel');
        } else navigate('/login');
    };

    return {
        hasAccess,
        isAdmin,
        redirectAfterLogging,
    };
};