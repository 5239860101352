import { Block } from '../blocks';
import { I18nEntry } from '../i18n';

interface SelectOption {
    label: string;
    value: string;
}

export interface RecipeDesignSeriesEntity {
    id: string;
    name: I18nEntry;
    startX: string;
    endX: string;
    y: Block[];
    position: number;
    points: SeriesPoint[];
    lineColor: string;
    lineStyle: number;
    repeatCount?: string;
    repeatBy?: string;
}

export interface RecipeDesignSeriesDetails {
    id: string;
    name: I18nEntry;
    startX?: SelectOption;
    endX?: SelectOption;
    y: Block[];
    position: number;
    points: SeriesPointRes[];
    lineColor: string;
    lineStyle: number;
    repeatCount?: SelectOption;
    repeatBy?: SelectOption;
}

export interface RecipeDesignSeriesReq {
    name: I18nEntry;
    designId: string;
    startX: string;
    endX: string;
    y: Block[];
    points: SeriesPoint[];
    lineColor: string;
    lineStyle: number;
    repeatCount?: string;
    repeatBy?: string;
}

export interface SeriesPoint {
    x: string;
    y: string;
}

export interface SeriesPointRes {
    x?: SelectOption;
    y?: SelectOption;
}

export enum LineStyleType {
    SOLID,
    DOTTED,
    DASHED,
    DASHED_WITH_DOT,
    DASHED_WITH_TWO_DOTS,
    DASHED_WITH_THREE_DOTS,
}
