import { FC } from 'react';
import { Spinner, Center as CenterChakra, CenterProps } from '@chakra-ui/react';

interface AppSpinnerInterface extends CenterProps {
  fullScreen?: boolean;
}

export const AppSpinner: FC<AppSpinnerInterface> = ({ fullScreen, ...props }) => (
  <CenterChakra width={fullScreen ? '100vw' : '100%'} height={fullScreen ? '100vh' : '100%'} {...props}>
    <Spinner size="xl" color="main" />
  </CenterChakra>
);
