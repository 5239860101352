import { I18nEntry } from '../../types';

export interface ClientEntity {
	name: string;
	industry: string;
	createdAt: Date;
	id: string;
	internalId: string;
	city: string;
	country: string;
	postalCode: string;
	street: string;
	streetNumber: string;
	addressAdditionalInfo: I18nEntry;
}

export enum ClientType {
	COMPANY,
	PRIVATE,
}

export interface ClientListDetails {
	id: string;
	industry: string;
	internalId: string;
	city: string;
	country: string;
	postalCode: string;
	street: string;
	streetNumber: string;
	addressAdditionalInfo: I18nEntry;
	name: string;
}

export interface ClientDetails {
	id: string;
	industry: string;
	internalId: string;
	city: string;
	country: string;
	postalCode: string;
	street: string;
	streetNumber: string;
	name: string;
	addressAdditionalInfo: I18nEntry;
}

export interface ClientPreview {
	name: string;
}

export interface CreateClientReq {
	internalId: string;
	industry?: string;
	city: string;
	country: string;
	postalCode: string;
	street: string;
	streetNumber: string;
	addressAdditionalInfo: I18nEntry;
	name: string;
}

export interface UpdateClientReq extends CreateClientReq { }
