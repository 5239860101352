import { AxiosResponse } from 'axios';
import { getErrorMessage } from '../types/getErrorMessage';
import { SetError } from '../types/error';
import { errorNotification } from './handleNotifications';
import { __ } from './i18n';

export const handleError = (response: AxiosResponse, setError?: SetError): void => {
  if (!response) {
    console.log('!response');
    errorNotification(getErrorMessage(response));
    return;
  }

  const { status, data } = response;
  const { message } = data;
  switch (status) {
    case 401:
      break;
    case 400:
      if (typeof message === 'object') {
        message.map((mes: string) => errorNotification(mes));
      } else errorNotification(getErrorMessage(data.error_code));
      break;
    default:
      errorNotification(getErrorMessage(data.error_code));
  }
};