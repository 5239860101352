import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import {
  ComponentDetails,
  I18nEntry,
  OfferRecipeDetails,
  ProductDetails,
  FullRecipeResponse,
  RecipeFieldType,
  RecipeProductDetails,
  RecipeSectionDetails,
  RecipeServiceDetails,
  ServiceDetails,
  RecipeGroupEntity,
  BlockType,
  RecipeComponentEntity,
  RecipeServiceEntity,
  RecipeProductEntity
} from 'pol-met-types';

import { Parser as FormulaParser } from 'hot-formula-parser';
import { flattenBlocks } from '../../utils/blocks';
import { CONFIGURATOR_OFFER_MODAL_TYPES } from '../../components/Common/Modals/Patterns/Configurator/Offer';
import { Category } from '../../../../pol-met-back/src/category/category.entity';
import { Unit } from '../../../../pol-met-back/src/unit/unit.entity';

export interface Visibilities {
  [key: string]: boolean;
}

export interface Values {
  [key: string]: string | number | boolean | null;
}

export interface I18nValues {
  [key: string]: I18nEntry;
}

export interface FieldValue {
  fieldId: string;
  value: string | number | boolean;
}

export interface Quantities {
  [key: string]: number;
}

export interface Percentages {
  [key: string]: number;
}

export interface FieldMarkUp {
  fieldId: string;
  markUp: number;
}

export interface AddProductToOffer {
  product: ProductDetails;
  quantity: number;
  group: RecipeGroupEntity;
}

export interface AddServiceToOffer {
  service: ServiceDetails;
  quantity: number;
  group: RecipeGroupEntity;
}

export interface AddComponentToOffer {
  component: ComponentDetails;
  quantity: number;
  group: RecipeGroupEntity;
}

export interface RemoveAdditional {
  type: CONFIGURATOR_OFFER_MODAL_TYPES | undefined;
  id: string;
  group: RecipeGroupEntity;
}

export interface VisualizationState {
  recipe: FullRecipeResponse | null;
  calculationUuid: string;
  sectionVisibility: Visibilities;
  groupVisibility: Visibilities;
  infoValues: Values;
  infoVisibility: Visibilities;
  fieldValues: Values;
  fieldVisibility: Visibilities;
  quantities: Quantities;
  itemVisibility: Visibilities;
  markUp: Percentages;
  totalArea: number;
}

const initialState: VisualizationState = {
  recipe: null,
  calculationUuid: v4(),
  sectionVisibility: {},
  groupVisibility: {},
  infoValues: {},
  infoVisibility: {},
  fieldValues: {},
  fieldVisibility: {},
  quantities: {},
  itemVisibility: {},
  markUp: {},
  totalArea: 0,
};

export const visualizationSlice = createSlice({
  name: 'visualization',
  initialState,
  reducers: {
    setRecipeState: (state, action: PayloadAction<OfferRecipeDetails>) => {
      const offerRecipe = action.payload;
      state.recipe = offerRecipe.recipe;
      state.calculationUuid = offerRecipe.calculationUuid;
      state.sectionVisibility = offerRecipe.sectionVisibility;
      state.groupVisibility = offerRecipe.groupVisibility;
      state.infoValues = offerRecipe.infoValues;
      state.infoVisibility = offerRecipe.infoVisibility;
      state.fieldValues = offerRecipe.fieldValues;
      state.quantities = offerRecipe.quantities;
      state.itemVisibility = offerRecipe.itemVisibility;
      state.markUp = offerRecipe.markUp;
    },
    setRecipe: (state, action: PayloadAction<FullRecipeResponse | null>) => {
      let recipe: FullRecipeResponse | null = action.payload;
      // IF RECIPE IS NULL THEN CLEAT STATE
      if (!recipe) return initialState;

      const initialMarkUp: Percentages = {};

      recipe.groups.forEach(group => {
        group.products.forEach(recipeProduct => {
          initialMarkUp[recipeProduct.id] = recipeProduct.product.markUp;
        })
        group.services.forEach(recipeService => {
          initialMarkUp[recipeService.id] = recipeService.service.markUp;
        })
        group.components.forEach(recipeComponent => {
          recipeComponent.component.products.forEach(componentProduct => {
            if (!componentProduct.id) return;
            initialMarkUp[componentProduct.id] = componentProduct.product.markUp;
          })
          recipeComponent.component.services.forEach(componentService => {
            if (!componentService.id) return;
            initialMarkUp[componentService.id] = componentService.service.markUp;
          })

        })
      })

      state.markUp = initialMarkUp;
      state.recipe = recipe;
    },
    setFieldValue: (state, action: PayloadAction<FieldValue>) => {
      state.calculationUuid = v4();
      state.fieldValues = {
        ...state.fieldValues,
        [action.payload.fieldId]: action.payload.value,
      };
    },
    updateItemMarkUp: (state, action: PayloadAction<FieldMarkUp>) => {
      console.log(
        {
          ...state.markUp,
          [action.payload.fieldId]: Math.round(action.payload.markUp * 100) / 100,
        }
      )
      state.markUp = {
        ...state.markUp,
        [action.payload.fieldId]: Math.round(action.payload.markUp * 100) / 100,
      }
    },
    removeAdditional: (state, action: PayloadAction<RemoveAdditional>) => {
      if (state.recipe) {
        state.recipe.groups = state.recipe?.groups.map((_group) => {
          if (_group.id === action.payload.group.id) {
            if (action.payload.type === CONFIGURATOR_OFFER_MODAL_TYPES.PRODUCTS) return { ..._group, products: _group.products.filter(groupProduct => groupProduct.product.id !== action.payload.id) };
            if (action.payload.type === CONFIGURATOR_OFFER_MODAL_TYPES.SERVICES) return { ..._group, services: _group.services.filter(groupService => groupService.service.id !== action.payload.id) };
            if (action.payload.type === CONFIGURATOR_OFFER_MODAL_TYPES.COMPONENTS) return { ..._group, components: _group.components.filter(groupComponent => groupComponent.id !== action.payload.id) };
          }
          return _group;
        });
      }
    },
    addProductToOffer: (state, action: PayloadAction<AddProductToOffer>) => {
      const newUuid = v4();
      const { product, quantity, group } = action.payload;

      state.quantities = {
        ...state.quantities,
        [newUuid]: quantity,
      };

      state.itemVisibility = {
        ...state.itemVisibility,
        [newUuid]: true,
      };
      state.markUp = {
        ...state.markUp,
        [newUuid]: (Math.round(product.markUp * 100) / 100) || 0,
      }

      if (state.recipe) {
        state.recipe.groups = state.recipe?.groups.map((_group) => {
          if (_group.id === group.id) {
            const newProduct: RecipeProductEntity = {
              id: newUuid,
              product: {
                ...product,
                isAdditional: true,
              },
              position: Number(_group.products[_group.products.length - 1] || 1),
              quantity: [
                {
                  type: BlockType.VALUE,
                  uuid: newUuid,
                  value: quantity,
                },
              ],
              condition: [],
              markUp: [],
            };
            return {
              ..._group,
              products: [
                ..._group.products,
                { ...newProduct }
              ]
            }
          }
          return _group;
        })
      }
    },
    addServiceToOffer: (state, action: PayloadAction<AddServiceToOffer>) => {
      const newUuid = v4();
      const { service, quantity, group } = action.payload;

      state.quantities = {
        ...state.quantities,
        [newUuid]: quantity,
      };

      state.itemVisibility = {
        ...state.itemVisibility,
        [newUuid]: true,
      };

      state.markUp = {
        ...state.markUp,
        [newUuid]: (Math.round(service.markUp * 100) / 100) || 0,
      }

      if (state.recipe) {
        state.recipe.groups = state.recipe?.groups.map((_group) => {
          if (_group.id === group.id) {
            const newService: RecipeServiceEntity = {
              id: newUuid,
              service: {
                ...service,
                isAdditional: true,
                category: service.category as Category,
                unit: service.unit as Unit,
              },
              position: Number(_group.services[_group.services.length - 1] || 1),
              quantity: [
                {
                  type: BlockType.VALUE,
                  uuid: newUuid,
                  value: quantity,
                },
              ],
              condition: [],
              markUp: [],
            };
            return {
              ..._group,
              services: [
                ..._group.services,
                { ...newService },
              ]
            };
          }
          return _group;
        })
      }
    },
    addComponentToOffer: (state, action: PayloadAction<AddComponentToOffer>) => {
      const newUuid = v4();
      const { component, quantity, group } = action.payload;

      component.products = [...component.products].map((item) => {
        const newUuidProduct = v4();
        if (item.quantity) {
          state.quantities = {
            ...state.quantities,
            [newUuidProduct]: item.quantity,
          };
        }
        state.markUp = {
          ...state.markUp,
          [newUuidProduct]: item.product?.markUp || 0,
        }

        item.id = newUuidProduct;
        return item;
      });

      component.services = [...component.services].map((item) => {
        const newUuidService = v4();
        if (item.quantity) {
          state.quantities = {
            ...state.quantities,
            [newUuidService]: item.quantity,
          };
        }
        state.markUp = {
          ...state.markUp,
          [newUuidService]: item.service.markUp || 0,
        }

        item.id = newUuidService;
        return item;
      });

      // COMPONENT QUANTITY MARKUP AND VISIBILITY
      state.quantities = {
        ...state.quantities,
        [newUuid]: quantity,
      };

      state.markUp = {
        ...state.markUp,
        [newUuid]: 0,
      };

      state.itemVisibility = {
        ...state.itemVisibility,
        [newUuid]: true,
      };

      if (state.recipe) {
        state.recipe.groups = state.recipe?.groups.map((_group) => {
          if (_group.id === group.id) {
            const newComponent: RecipeComponentEntity = {
              id: newUuid,
              component: {
                ...component,
                isAdditional: true,
              },
              position: Number(_group.components[_group.components.length - 1] || 1),
              quantity: [
                {
                  type: BlockType.VALUE,
                  uuid: newUuid,
                  value: quantity,
                },
              ],
              condition: [],
              markUp: [],
            };
            return {
              ..._group,
              components: [
                ..._group.components,
                { ...newComponent },
              ]
            };
          }
          return _group;
        })
      }
    },
    refreshData: (state, action: PayloadAction<FullRecipeResponse>) => {
      console.log('refreshing data');
      const recipe: FullRecipeResponse = action.payload;
      const parser = new FormulaParser();
      if (!recipe) {
        return;
      }

      const allValues = {
        ...state.fieldValues,
        ...state.infoValues,
      };
      let newSectionVisibility: Visibilities = state.sectionVisibility;
      let newGroupVisibility: Visibilities = state.groupVisibility;
      let newInfoValues: Values = state.infoValues;
      let newInfoVisibilities: Visibilities = state.infoVisibility;
      let newFieldValues: Values = state.fieldValues;
      let newFieldVisibilities: Visibilities = state.fieldVisibility;
      let newQuantities: Quantities = state.quantities;
      let newItemVisibilities: Visibilities = state.itemVisibility;
      let newMarkUp: Percentages = state.markUp;
      let newUuid = state.calculationUuid;
      let newTotalArea = state.totalArea;

      let mappedNames: I18nValues = {};
      [...recipe.infos, ...recipe.inputs].forEach((val) => {
        // Changed from val.title
        mappedNames[val.id] = val.name;
      });

      // RECIPE SECTIONS
      recipe.sections.forEach((section: RecipeSectionDetails) => {
        // SECTION CONDITION
        if (section.condition) {
          const formulaVisibility = flattenBlocks(
            section.condition,
            '',
            { ...allValues, ...newFieldValues, ...newInfoValues },
            mappedNames
          );

          const result = parser.parse(formulaVisibility).result;

          newSectionVisibility = {
            ...newSectionVisibility,
            [section.id]: section.condition!.length > 0 ? result : true,
          };
        }
        // SECTION INFO FIELDS
        section.infos.forEach((info) => {
          if (info.condition) {
            const condition = info.condition || [];
            const formulaVisibility = flattenBlocks(
              condition,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );

            const result = parser.parse(formulaVisibility).result;

            if (
              newInfoValues[info.id] !== null &&
              condition.length > 0 &&
              !!result !== true
            ) {
              newUuid = v4();
              newInfoValues = {
                ...newInfoValues,
                [info.id]: null,
              };
            }
            // console.log('result: ', result, condition, condition!.length > 0 ? 'dupa' : true,);
            newInfoVisibilities = {
              ...newInfoVisibilities,
              [info.id]: condition!.length > 0 ? result : true,
            };
          } else {
            newInfoVisibilities = {
              ...newInfoVisibilities,
              [info.id]: true,
            };
          }
          // console.log('visualisationSlice newInfoVisibilities in else: ', newInfoVisibilities);
          if (info.value && newInfoVisibilities[info.id]) {
            const value = info.value || [];
            const formulaValue = flattenBlocks(
              value,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );

            const result = parser.parse(formulaValue).result;

            if (result !== newInfoValues[info.id]) {
              newInfoValues = {
                ...newInfoValues,
                [info.id]: value.length > 0 ? result : null,
              };
            }
            // console.log('newInfoValues: ', newInfoValues);
          }
        });
        // SECTION INPUT FIELDS
        section.inputs.forEach((input) => {
          if (input.condition) {
            const formulaVisibility = flattenBlocks(
              input.condition,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );

            const result = parser.parse(formulaVisibility).result;

            if (
              newFieldValues[input.id] !== null &&
              newFieldValues[input.id] !== false &&
              input.condition.length > 0 &&
              !!result !== true
            ) {
              newUuid = v4();

              newFieldValues = {
                ...newFieldValues,
                [input.id]:
                  input.type === RecipeFieldType.CHECKBOX ? false : null,
              };
            }

            newFieldVisibilities = {
              ...newFieldVisibilities,
              [input.id]: input.condition!.length > 0 ? result : true,
            };
          } else {
            newFieldVisibilities = {
              ...newFieldVisibilities,
              [input.id]: true,
            };
          }
        });
      });

      // RECIPE GROUPS
      recipe.groups.forEach((group) => {
        if (group.condition) {
          const formulaVisibility = flattenBlocks(
            group.condition,
            '',
            { ...allValues, ...newFieldValues, ...newInfoValues },
            mappedNames
          );

          newGroupVisibility = {
            ...newGroupVisibility,
            [group.id]:
              group.condition!.length > 0
                ? parser.parse(formulaVisibility).result
                : true,
          };
        }

        // RECIPE PRODUCTS SERVICES
        [...group.products, ...group.services].forEach((item) => {
          if (item.quantity) {
            const formulaQuantity = flattenBlocks(
              item.quantity,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );

            newQuantities = {
              ...newQuantities,
              [item.id]: parser.parse(formulaQuantity).result,
            };
          }
          if (item.condition) {
            const formulaVisibility = flattenBlocks(
              item.condition,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );

            newItemVisibilities = {
              ...newItemVisibilities,
              [item.id]:
                item.condition!.length > 0
                  ? parser.parse(formulaVisibility).result
                  : true,
            };
          }

          // MARKUP
          if (!state.markUp[item.id]) {
            const formulaMarkUp = flattenBlocks(
              item.markUp,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );
            const result = parser.parse(formulaMarkUp).result;

            if ((item as RecipeProductDetails).product) {
              newMarkUp = {
                ...newMarkUp,
                [item.id]: item.markUp!.length > 0 ? result
                  : typeof newMarkUp[(item as RecipeProductDetails).id] !== undefined
                    ? newMarkUp[(item as RecipeProductDetails).id]
                    : (item as RecipeProductDetails).product.markUp || 0,
              };
            } else if ((item as RecipeServiceDetails).service) {
              newMarkUp = {
                ...newMarkUp,
                [item.id]: item.markUp!.length > 0 ? result
                  : typeof newMarkUp[(item as RecipeServiceDetails).id] !== undefined
                    ? newMarkUp[(item as RecipeServiceDetails).id]
                    : (item as RecipeServiceDetails).service.markUp || 0,
              };
            }
          }
        });

        // COMPONENTS
        [...group.components].forEach((component) => {
          // COMPONENT QUANTITY
          if (component.quantity) {
            const formulaQuantity = flattenBlocks(
              component.quantity,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );

            newQuantities = {
              ...newQuantities,
              [component.id]: parser.parse(formulaQuantity).result,
            };
          }
          // COMPONENT MARKUP
          if (component.markUp) {
            const formulaMarkUp = flattenBlocks(
              component.markUp,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );

            newMarkUp = {
              ...newMarkUp,
              [component.id]: parser.parse(formulaMarkUp).result || 0,
            };
          }
          // COMPONENT CONDITION
          if (component.condition) {
            const formulaVisibility = flattenBlocks(
              component.condition,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );

            newItemVisibilities = {
              ...newItemVisibilities,
              [component.id]:
                component.condition!.length > 0
                  ? parser.parse(formulaVisibility).result
                  : true,
            };
          }
          // COMPONENT PRODUCTS
          [...component.component.products].forEach((item) => {
            if (item.id) {
              if (item.quantity) {
                newQuantities = {
                  ...newQuantities,
                  [item.id]: item.quantity,
                };
              }



              newMarkUp = {
                ...newMarkUp,
                [item.id]: typeof newMarkUp[item.id] !== undefined
                  ? newMarkUp[item.id] : item.product.markUp || 0
              }
            }
          });
          // COMPONENT SERVICES
          [...component.component.services].forEach((item) => {
            if (item.id) {
              if (item.quantity) {
                newQuantities = {
                  ...newQuantities,
                  [item.id]: item.quantity,
                };
              }

              newMarkUp = {
                ...newMarkUp,
                [item.id]: typeof newMarkUp[item.id] !== undefined
                  ? newMarkUp[item.id] : item.service.markUp || 0
              }
            }
          });
        });


      });




      // AREA
      if (recipe.area) {
        const formulaQuantity = flattenBlocks(
          recipe.area,
          '',
          { ...allValues, ...newFieldValues, ...newInfoValues },
          mappedNames
        );

        newTotalArea = parser.parse(formulaQuantity).result ?? 0;
      }

      console.group('RefreshData: ');
      // console.log('newSectionVisibility: ', newSectionVisibility);
      // console.log('newInfoValues: ', { ...newInfoValues });
      // console.log('newInfoVisibilities: ', newInfoVisibilities);
      // console.log('newArea: ', newArea);
      // console.log('newFieldVisibilities: ', newFieldVisibilities);
      // console.log('newQuantities: ', newQuantities);
      // console.log('newItemVisibilities: ', newItemVisibilities);
      // console.log('newUuid: ', newUuid);
      console.log('newMarkUp: ', newMarkUp);
      console.log('recipe.groups: ', recipe.groups);
      console.groupEnd();

      state.sectionVisibility = newSectionVisibility;
      state.groupVisibility = newGroupVisibility;
      state.infoValues = newInfoValues;
      state.infoVisibility = newInfoVisibilities;
      state.fieldValues = newFieldValues;
      state.fieldVisibility = newFieldVisibilities;
      state.calculationUuid = newUuid;
      state.quantities = newQuantities;
      state.itemVisibility = newItemVisibilities;
      state.markUp = newMarkUp;
      state.totalArea = newTotalArea;
    },
  },
});

export const {
  setRecipe,
  setFieldValue,
  updateItemMarkUp,
  refreshData,
  addProductToOffer,
  addServiceToOffer,
  addComponentToOffer,
  removeAdditional,
  setRecipeState,
} = visualizationSlice.actions;

export default visualizationSlice.reducer;
