import { FC } from 'react';
import { Box, Divider } from '@chakra-ui/layout';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getSchema } from '../../../formsSchemas/auth/restorePass';
import { useApi } from '../../../hooks/api/useApi';
import { successNotification } from '../../../helpers/handleNotifications';
import { FormField } from '../../../components/FormField';
import { InputText } from '../../../components/FormField/InputText';
import { UnauthorizedLayout } from '../../../components/Layouts/Unauthorized';
import { __ } from '../../../helpers/i18n';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { HeaderTitle } from '../../../components/Common/Header/HeaderTitle';
import { Button } from '../../../components/Common/Button';
import { StackColumn } from '../../../components/Common/Auth/StackColumn';
import { LogoBoxAuth } from '../../../components/Common/Auth/LogoBoxAuth';
import { FlexAuth } from '../../../components/Common/Auth/FlexAuth';

interface ResetPassReq {
  password: string;
  password2: string;
  updateToken: string;
  userId: string;
}

export const RestorePass: FC = () => {
  const { updateToken, userId } = useParams();
  const navigate = useNavigate();
  const methods = useForm<ResetPassReq>({
    mode: 'onChange',
    // resolver: yupResolver(schema),
    resolver: yupResolver(getSchema()),
    defaultValues: {
      password: '',
      password2: '',
      updateToken,
      userId: userId,
    },
  });
  const { patch, inProgress } = useApi({ methods });

  const submit = async (data: ResetPassReq) => {
    const res = await patch('/user/reset-pass', {
      password: data.password,
      updateToken: data.updateToken,
      userId: data.userId,
    });
    // TODO: Find better solution
    if (res === null) {
      successNotification(__('application.resetPasswordSuccess'));
      navigate('/login');
    }
  };

  return (
    <UnauthorizedLayout isLoading={inProgress}>
      <FlexAuth>
        <Link to="/login">
          <LogoBoxAuth />
        </Link>
        <Divider
          orientation={'vertical'}
          p="0 2rem"
          height={['0', '0', '280px', '300px']}
        />
        <Box width={['200px', '300px', '400px', '400px']}>
          <HeaderTitle text="application.resetPassword" />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submit)}>
              <StackColumn>
                <FormField
                  name="password"
                  labelTxt="labels.password"
                  width="100%"
                >
                  <InputText
                    pwd="password2"
                    placeholder="placeholders.password"
                  />
                </FormField>
                <FormField
                  name="password2"
                  labelTxt="labels.repeatPassword"
                  width="100%"
                >
                  <InputText pwd placeholder="placeholders.password" />
                </FormField>
                <Box textAlign="right" width="100%">
                  <Button
                    type="submit"
                    variant="main"
                    text="action.submit"
                    disabled={!methods.formState.isValid || inProgress}
                  />
                </Box>
              </StackColumn>
            </form>
          </FormProvider>
        </Box>
      </FlexAuth>
    </UnauthorizedLayout>
  );
};
